import { Login } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles'

import AppTheme from '../../layouts/AppTheme'

const useStyles = makeStyles(theme => ({
    main: {
        background: '#FFF', 
    }
}))
  
const LoginPage = () => (
    <Login
        // A random image that changes everyday
        theme={AppTheme}
        classes={useStyles()}
    />
);

export default LoginPage