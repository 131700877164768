import React, { useState, useRef } from 'react';
import {
    useNotify,
    Title,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { RedocStandalone } from 'redoc';


const useStyles = makeStyles({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    frame: {
        width: 'calc(100% - 30px)',
        height: '100%',
        border: 'none',
        position: 'absolute',
        overflowY: 'auto',
    }
});

const CustomRouteLayout = (props) => {
//    useAuthenticated()
    const classes = useStyles();
    const notify = useNotify();
    
    const [contentSpec, setContentSpec] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    React.useEffect(() => {
        let isMounted = true;               // note mutable flag
        // const { access_token } = JSON.parse(localStorage.getItem('auth'));
        const docURL = ((process.env.NODE_ENV === 'production') ? '' : 'http://localhost:8081') + '/api/v1/openapi_ext.json';
        let headers = {"Content-Type": "application/json"};
        // headers["Authorization"] = `Bearer ${access_token}`
        fetch(docURL, {headers,})
            .then(response => {
                response.json()
                    .then(data => {
                        if (isMounted) {
                            setContentSpec(data);
                            setIsLoading(false);
                        }
                    });
            })
            .catch(function() {
                // handle the error
                notify('Sorry, but the documentation cannot be loaded right now.', 'warning');
            });;
        return () => { isMounted = false; };
    }, [notify]);

    const contentSpecRef = useRef();
    contentSpecRef.current = contentSpec;
    
    return (
        <Card className={classes.root}>
            <Title title="Documentation" />
            <CardContent className={classes.frame}>
                {!isLoading
                 ? <RedocStandalone
                       spec={contentSpecRef.current}
                       options={{
                           nativeScrollbars: true,
                           hideDownloadButton: true,
                           theme: { 
                               colors: { primary: { main: '#C87761' } },
                               rightPanel: { backgroundColor: '#222' }
                           },
                       }}
                   />
                 : <p>Loading</p>
                }
            </CardContent>
        </Card>
    );
};

export default CustomRouteLayout;
