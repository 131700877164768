import * as React from 'react';
import {
  Create,
  PasswordInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useAuthenticated,

  useNotify,
  useRefresh,
  useRedirect,

  required,
  minLength,
  maxLength
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  content: {
    width: 'calc(100% - 30px)',
    height: '100%',
    border: 'none',
    position: 'absolute'
  },
  form: {
    width: '100%'
  }
});

const ChangePasswordToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const validateEditSettings = async (values) => {
  const errors = [];

  if (!values['new-password'] || (values['new-password'] !== values['confirm-password'])) {
    errors['confirm-password'] = 'The new password must be equal to the confirmed password!';
  }

  return errors;
}

const validatePasswordInput=[required(), minLength(8), maxLength(900)];
export const PasswordChange=({ ...props }) => {
  useAuthenticated();
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccessChangePassword = () => {
    notify(`Password Changed!`);
    redirect('/');
    refresh();
  };

  const transform = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (typeof data[key] === "string" && data[key].trim().length === 0) continue;
      sanitizedData[key] = data[key];
    }

    return sanitizedData;
  };

  const onFailureChangePassword = (error) => {
    const errorMsg = typeof(error.body.error.message) == 'object' ? JSON.stringify(error.body.error.message) : error.body.error.message;
    console.log(errorMsg, ' ', typeof(error));
    notify(`Could not change password: ${errorMsg}`, { type: 'warning', autoHideDuration: 3000 });
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="div">
          Change Password
        </Typography>
        <Create
          basePath='/'
          resource='password/me'
          redirect={false}
          title="Change Password"
          transform={transform}
          onFailure={onFailureChangePassword}
          onSuccess={onSuccessChangePassword} >
          <SimpleForm className={classes.form} toolbar={<ChangePasswordToolbar />} validate={validateEditSettings}>
            <PasswordInput className={classes.form} source="old-password" label="Old Password" inputProps={{ autoComplete: 'off' }} />
            <PasswordInput className={classes.form} source="new-password" label="New Password" inputProps={{ autoComplete: 'off' }} validate={validatePasswordInput} />
            <PasswordInput className={classes.form} source="confirm-password" label="Confirm Password" inputProps={{ autoComplete: 'off' }} validate={validatePasswordInput} />
          </SimpleForm>
        </Create>
      </CardContent>
    </Card>
  );
};

export default PasswordChange;
