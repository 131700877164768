// in src/App.js
import * as React from "react";
import { Route } from 'react-router-dom';
import { Admin, Resource } from 'react-admin';

import SettingsIcon from '@material-ui/icons/Settings';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import authProvider from './providers/authProvider';
import extendDataProvider from './providers/dataProvider';

import AppLayout from './layouts/AppLayout';
import AppTheme from './layouts/AppTheme';

import Home from './pages/home';
import login from './pages/login';
import documentation from './pages/documentation';
import test from './pages/test';
import keys from './pages/keys';
import profile from './pages/profile';
import password from './pages/password';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const GOOGLE_RECAPTCHA_V3_PRODUCTION_KEY = "6LcODIgeAAAAAA4I49gqYXpT6t0cMx0wO_8xJzPn";
const GOOGLE_RECAPTCHA_V3_DEV_KEY = "6LdZu-4eAAAAACx4nhlFE1uktxcTFx9dHatIBkDs";

const App = () => {

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={((process.env.REACT_APP_SENTRY_ENV === 'production') || (process.env.REACT_APP_SENTRY_ENV === 'staging') || (process.env.REACT_APP_SENTRY_ENV === 'test-server')) ? GOOGLE_RECAPTCHA_V3_PRODUCTION_KEY : GOOGLE_RECAPTCHA_V3_DEV_KEY}
      language="en"
    >
      <Admin
        theme={AppTheme}
        loginPage={login}
        disableTelemetry
        layout={AppLayout}
        dashboard={Home}
        dataProvider={extendDataProvider}
        authProvider={authProvider}
        customRoutes={[
          <Route
            path="/docs"
            component={documentation}
          />,
          <Route
            path="/test"
            component={test}
          />,
          <Route
            key="profile"
            path="/profile"
            component={profile.edit}
          />,
          <Route
            key="password_change"
            path="/password/me"
            component={password.create}
          />,
        ]}>
          <Resource name="keys/me" list={keys.list} options={{ label: 'API Keys' }} icon={VpnKeyIcon} />
          <Resource name="profile" options={{ label: 'Settings' }} icon={SettingsIcon} />
          <Resource name="password/me" create={password.create} options={{ label: 'Change Password' }} icon={SettingsIcon} />
      </Admin>
    </GoogleReCaptchaProvider>
  )};

export default App;
