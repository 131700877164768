import * as React from 'react';
import {
  Edit,
  TextField,
  SaveButton,
  SimpleForm,
  Toolbar,
  useAuthenticated,

  useNotify,
  useRefresh,
  useRedirect
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiButton from '@material-ui/core/Button';

import CustomContent from '../../components/customContent';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  content: {
    width: 'calc(100% - 30px)',
    height: '100%',
    border: 'none',
    position: 'absolute'
  },
  form: {
    width: '100%'
  }
});

const UserEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

export const ProfileEdit = ({ staticContext, ...props }) => {
  useAuthenticated();
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccessEditProfile = () => {
    notify(`Settings Updated`);
    redirect('/');
    refresh();
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Edit
          basePath='/'
          mutationMode="pessimistic"
          onSuccess={onSuccessEditProfile}
          resource='profile'
          id='me'
          redirect={false}
          title="Settings"
          {...props}
        >
          <SimpleForm className={classes.form} toolbar={<UserEditToolbar />}>
            <TextField source="username" />
            <TextField source="email" />
            <CustomContent><MuiButton variant="contained" color="primary" href="/#/password/me">Change Password</MuiButton></CustomContent>
          </SimpleForm>
        </Edit>
      </CardContent>
    </Card>
  );
};

export default ProfileEdit;
