// in src/AppLayout.js
import { Layout } from 'react-admin';
import MyMenu from './AppMenu';

const AppLayout = (props) => <Layout
    {...props}
    menu={MyMenu}
/>;

export default AppLayout;
