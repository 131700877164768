// in src/Home.js
import * as React from "react"
import Card from '@material-ui/core/Card'

import { 
    Title,
    useAuthenticated
} from 'react-admin'
const Home = (props) => {
    useAuthenticated()
    return (
    <Card>
        <Title title="Kumux API Pro Developer Portal" />
    </Card>
    )
};


export default Home;