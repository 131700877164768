import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#C87761',
      },
      secondary: {
        main: '#C87761',
      },
    },
  });
  
export default theme