// in src/authProvider.js
const public_paths = ['#/public/demo_user/create', '#/docs'];

const authProvider = {
  login: ({ username, password }) => {
    const apiBaseURL = (process.env.NODE_ENV === 'production') ? '' : 'http://localhost:8081';
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    const request = new Request(`${apiBaseURL}/admin/api/v1/token`, {
      method: 'POST',
      body: formData
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(auth => {
        localStorage.setItem('auth', JSON.stringify(auth));
      })
      .catch(() => {
        throw new Error('Network error');
      });
  },
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return (localStorage.getItem('auth') || public_paths.includes(window.location.hash))
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    return Promise.resolve();
  }
};

export default authProvider;
