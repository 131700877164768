import React, { useState } from 'react';
import {
    useAuthenticated,
    useNotify,
    Title,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// import 'rapidoc'
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "./theme-muted.css";

const useStyles = makeStyles({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    frame: {
        width: 'calc(100% - 60px)'
    }
});

const TestLayout = (props) => {
    useAuthenticated();
    
    const classes = useStyles();
    const notify = useNotify();
    
    const [content, setContent] = useState({});
    const [isLoading, setIsLoading] = useState(true); // eslint-disable-line no-unused-vars
    
    React.useEffect(() => {
        const { access_token } = JSON.parse(localStorage.getItem('auth'));
        const docURL = ((process.env.NODE_ENV === 'production') ? '' : 'http://localhost:8081') + '/documentation/openapi.json';
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${access_token}`;
        fetch(docURL, {headers,})
            .then(response => response.json())
            .then(data => {
                //docEl.current.loadSpec(data)
                setContent(data);
                setIsLoading(false);
            })
            .catch(error => {
                notify('Sorry, but the tests cannot be loaded right now.', 'warning');
            });
    }, [notify]);

    return (
        <Card className={classes.root}>
            <Title title="Test Client" />
            <CardContent className={classes.frame}>
                <SwaggerUI spec={content} defaultModelsExpandDepth={-1} />
                {/*
                <rapi-doc 
                    ref={docEl}
                    show-header = 'false'
                    show-info = 'false'
                    allow-authentication ='true'
                    allow-server-selection = 'false'
                    allow-api-list-style-selection ='false'
                    render-style = "view"
                    style = {{ height: "100%", width: "100%" }} 
                    primary-color = "#C87761"
                    nav-bg-color = "#FFF"
                    server-url = 'http://localhost:8081'
                />*/}
            </CardContent>
        </Card>
    );
};

export default TestLayout;
