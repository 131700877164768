import * as React from 'react';
import {
    Create,
    Button,
    SaveButton,
    SimpleForm,
    TextInput,
    Title,
    Toolbar,
    useRedirect,
    useAuthenticated,
    required,
} from 'react-admin';

import { v4 as uuidv4 } from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { NonInput } from '../../components/noninput';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    hidden: {
        visibility: 'hidden'
    },
    content: {
        width: 'calc(100% - 30px)',
        height: '100%',
        border: 'none',
        position: 'absolute'
    },
    form: {
        width: '100%'
    },
    button: {
        position: 'relative',
        fontWeight: 'bold',
        backgroudcolor: 'primary',
        color: 'secondary'
    }
});


const ToolbarCreateUser = ({ showApiKeys, actionCreateKey, ...props }) => {
    const classes = useStyles();

    return (
        <Toolbar {...props}>
            {showApiKeys
                ? <NonInput>
                    <Button
                        className={classes.button}
                        onClick={actionCreateKey}
                        label="Return">
                        <VpnKeyIcon />
                    </Button>
                </NonInput>
                : <SaveButton />
            }
        </Toolbar>
    );
};


const ApiKeysCreate = (props) => {
    useAuthenticated();
    const classes = useStyles();
    const [showApiKeys, setShowApiKeys] = React.useState(false);
    const redirect = useRedirect();

    const createKeyAction = () => {
        redirect('/keys/');
    };

    
    const generateUUID = () => {
        const uuid = uuidv4();
        return uuid;
    };

    
    const generatePassword = () => {
        var length = 32,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    };
    

  const [hiddenValues, setHiddenValue] = React.useState({ "key": generateUUID(), "secret": generatePassword() }); // eslint-disable-line no-unused-vars
    
    
    const transformDataBeforeCreation = data => {
        const createData = {
            ...hiddenValues,
            ...data
        };
        
        return createData;
    };

    
    const onSuccessCreateKey = (data) => {
        setShowApiKeys(true);
    };


    const onFailureCreateKey = (error) => {
        redirect('/keys/');
    };


    return (
        <Card className={classes.root}>
            <Title title="API " />
            <CardContent>
                <Create {...props}
                    onSuccess={onSuccessCreateKey}
                    onFailure={onFailureCreateKey}
                    transform={transformDataBeforeCreation} >
                    <SimpleForm
                        className={classes.form}
                        toolbar={<ToolbarCreateUser
                            showApiKeys={showApiKeys}
                                   actionCreateKey={createKeyAction} />}
                    >
                        <TextInput
                            source="description"
                            disabled={showApiKeys}
                            label="Descriptive Name"
                            defaultValue={'ADescriptiveNameForMyNewKey'}
                            className={classes.form}
                            validate={required()} />
                        {showApiKeys
                         ? <>
                             <TextInput
                                disabled
                                source="key"
                                label="API_KEY"
                                defaultValue={hiddenValues["key"]}
                                className={showApiKeys ? classes.form : classes.hidden}
                                validate={required()} />
                            <TextInput
                                disabled
                                source="secret"
                                label="API_SECRET"
                                defaultValue={hiddenValues["secret"]}
                                className={showApiKeys ? classes.form : classes.hidden}
                                validate={required()} />
                             <NonInput>
                               <p className={showApiKeys ? classes.form : classes.hidden}>
                                    Please write down in a secure place your API_KEY and API_SECRET.
                                    It can not be changed and we keep only a hashed version to protect your privacy.
                                    However you can always create another key and delete an old one.
                                </p>
                            </NonInput>
                         </>
                         : null
                        }
                    </SimpleForm>
                </Create>
            </CardContent>
        </Card>
    );
};

export default ApiKeysCreate;
