import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { getResources, MenuItemLink } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import BookIcon from '@material-ui/icons/Book';
import BuildIcon from '@material-ui/icons/Build';
import DefaultIcon from '@material-ui/icons/ViewList';
import Person from '@material-ui/icons/Person';

const resource_not_show_in_private_access = ['public/demo_user'];

const useStyles = makeStyles({
    menuWrapper: {
        marginTop: '30px'
    }
});

/*
const menuItems = [
    { name: 'docs', text: 'Documentation', icon: <BookIcon /> },
    { name: 'client', text: 'Test Client', icon: <BuildIcon /> },
    { name: 'api-keys', text: 'API Keys', icon: <VpnKeyIcon /> },
    { name: 'settings', text: 'Settings', icon: <SettingsIcon /> }
];
*/


function has_been_authenticated() {
    return localStorage.getItem('auth');
}

const Menu = ({ onMenuClick, logout }) => {

    const classes = useStyles();

    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    
    return (
        <div className={classes.menuWrapper}>
           {/*<DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />*/}
          <MenuItemLink
            key='docs'
            to={`/docs`}
            primaryText='Documentation'
            leftIcon={<BookIcon />}
            onClick={onMenuClick}
          />
          {has_been_authenticated()
           ? <MenuItemLink
               key='test'
               to={`/test`}
               primaryText='Test Client'
               leftIcon={<BuildIcon />}
               onClick={onMenuClick}
             />
           : [<MenuItemLink
                key="login"
                to={`/`}
                primaryText='LogIn'
                leftIcon={<Person />}
                onClick={onMenuClick}
              />]
          }
          {resources.map(resource => {
              return (
                  ((has_been_authenticated() &&
                    !(resource_not_show_in_private_access.includes(resource.name)))
                   ? <MenuItemLink
                          key={resource.name}
                          to={`/${resource.name}`}
                          primaryText={(resource.options && resource.options.label) || resource.name}
                          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                          onClick={onMenuClick}
                          sidebarIsOpen={open}
                        />
                   : null));
          })}
          {isXSmall && logout}
        </div>
    );
};

export default Menu;
